import {Carousel, Col, Row} from 'antd';
import {CarouselRef} from 'antd/lib/carousel';
import clsx from 'clsx';
import React from 'react';
import {useRef} from 'react';
import LeftAngle from '../../svgs/left-angle';
import * as styles from './index.module.scss';

export type ViewModel = {
  images: {
    url: string;
  }[];
};
type PropTypes = {
  vm: ViewModel;
};

export default function LuCarouselThumb({vm}: PropTypes) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const carouselRef1 = useRef<CarouselRef | null>();
  const carouselRef2 = useRef<CarouselRef | null>();
  return (
    <div className={styles.container}>
      <Carousel
        infinite
        swipe
        dots={false}
        draggable
        ref={slider => (carouselRef1.current = slider)}
        beforeChange={(_, next) => {
          setCurrentSlide(next);
        }}
        asNavFor={carouselRef2.current}
      >
        {vm.images.map(image => (
          <div key={image.url} className={styles.image}>
            <img src={image.url} />
          </div>
        ))}
      </Carousel>
      <Row className={styles.bottom} align="middle" wrap={false}>
        <Col
          className={styles.prev}
          onClick={() => carouselRef1.current?.prev()}
        >
          <LeftAngle />
        </Col>
        <Col flex={1}>
          <Carousel
            infinite
            swipe
            draggable
            dots={false}
            slidesToShow={4}
            ref={slider => (carouselRef2.current = slider)}
            asNavFor={carouselRef1.current}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 3,
                },
              },
            ]}
          >
            {vm.images.map((image, index) => (
              <div
                key={image.url}
                className={styles.slide}
                onClick={() => {
                  carouselRef1.current?.goTo(index);
                }}
              >
                <div className={clsx(styles.thumb, currentSlide === index && styles.active)}>
                  <img src={image.url} />
                </div>
              </div>
            ))}
          </Carousel>
        </Col>
        <Col
          className={styles.next}
          onClick={() => carouselRef1.current?.next()}
        >
          <LeftAngle />
        </Col>
      </Row>
    </div>
  );
}
