import {Col, Row} from 'antd';
import clsx from 'clsx';
import React from 'react';
import {GAP_SIZE_BASE} from '../../constants';
import * as styles from './index.module.scss';

export type ViewModel = {
  title: string;
  description: string;
}[]
type PropTypes = {
  vm: ViewModel
};

export default function SectionStatistics({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <Row
        className={styles.content}
        justify="center"
        align="middle"
        gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}
        wrap={false}
      >
        {vm.map(item => (
          <Col key={item.title} span={8} className={styles.item}>
            <div>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.description}>{item.description}</div>
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
}
