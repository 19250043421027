import clsx from 'clsx';
import React from 'react';
import DotBorder from './dot-border';
import * as styles from './index.module.scss';

type PropTypes = {
  className?: string;
  name: string;
  text: string | string[];
  imageUrl: string;
};

export default function LuPortrait({
  className,
  name,
  text,
  imageUrl,
}: PropTypes) {
  const textArray = typeof text === 'string' ? [text] : text;
  return (
    <div className={clsx(className, styles.portrait)}>
      <div className={styles.image}>
        <img src={imageUrl} alt={name} />
        <DotBorder className={styles.border} />
      </div>
      <div className={styles.name}>{name}</div>
      {textArray.map(string => (
        <div key={string} className={styles.text}>{string}</div>
      ))}
    </div>
  );
}
