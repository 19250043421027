import {Col, Row} from 'antd';
import clsx from 'clsx';
import React from 'react';
import TripleAngle from '../../svgs/triple-angle';
import * as styles from './index.module.scss';

export type ViewModel = {
  items: {
    title: string;
    content: string;
  }[];
};
type PropTypes = {
  vm: ViewModel;
};

export default function LuTimeline({vm}: PropTypes) {
  return (
    <div className={styles.timeline}>
      {vm.items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === vm.items.length - 1;
        return (
          <Row key={item.title} className={styles.item} align="middle" wrap={false}>
            <Col className={styles.indicator}>
              {!isFirst && <div className={clsx(styles.line)} />}
              <div className={styles.dot} >
                <div className={styles.triangle} />
              </div>
              {!isLast && <div className={clsx(styles.line)} />}
            </Col>
            <Col className={styles.main} flex={1}>
              <span className={styles.title}>{item.title}</span>
              <span className={styles.content}>{item.content}</span>
            </Col>
          </Row>
        );
      })}
    </div>
  );
}
