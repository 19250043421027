import {Col, Modal, Row} from 'antd';
import clsx from 'clsx';
import React from 'react';
import {useState} from 'react';
import {ReactNode} from 'react';
import {GAP_SIZE_2, GAP_SIZE_BASE} from '../../constants';
import * as styles from './index.module.scss';

export type ViewModel = {
  imageUrl: string;
  name: string;
  detail: ReactNode;
}[];
type PropTypes = {
  vm: ViewModel;
};

export default function SectionCertificationAndAudit({vm}: PropTypes) {
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  const activeItem = activeIndex == null ? null : vm[activeIndex];
  return (
    <Row
      className={styles.section}
      align="stretch"
      gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}
    >
      {vm.map((cert, index) => (
        <Col key={cert.name} md={6} span={12}>
          <div className={styles.card} onClick={() => setActiveIndex(index)}>
            <div />
            <div>
              <img className={styles.certImage} src={cert.imageUrl} />
            </div>
            <div>{cert.name}</div>
          </div>
        </Col>
      ))}
      {activeItem && (
        <div className={styles.mask} onClick={() => setActiveIndex(null)}>
          <div className={styles.content}>
            <Row align="middle" gutter={[GAP_SIZE_2, GAP_SIZE_2]}>
              <Col sm={8} span={24}>
                <img className={styles.image} src={activeItem.imageUrl} />
              </Col>
              <Col sm={16} span={24}>
                <div className={styles.name}>【{activeItem.name}】</div>
                <div>{activeItem.detail}</div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Row>
  );
}
