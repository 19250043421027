import React from 'react';

type PropTypes = {
  className: string;
};

export default function DotBorder({className}: PropTypes) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 316.27 316.38"
    >
      <g style={{isolation: 'isolate'}}>
        <g id="图层_2" data-name="图层 2">
          <g id="design">
            <g style={{mixBlendMode: 'multiply'}}>
              <path fill="currentColor" d="M32.45,62.16Z" />
              <path fill="currentColor" d="M38.56,54.66h0Z" />
              <circle fill="currentColor" cx="45.1" cy="47.53" r="0.03" />
              <path
                fill="currentColor"
                d="M52.08,40.87a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,52.08,40.87Z"
              />
              <circle fill="currentColor" cx="59.45" cy="34.57" r="0.06" />
              <path
                fill="currentColor"
                d="M67.2,28.85a.07.07,0,1,1,0-.14.07.07,0,0,1,0,.14Z"
              />
              <circle fill="currentColor" cx="75.29" cy="23.49" r="0.09" />
              <path
                fill="currentColor"
                d="M83.69,18.8a.1.1,0,0,1-.1-.1.11.11,0,0,1,.1-.1.1.1,0,0,1,.1.1A.09.09,0,0,1,83.69,18.8Z"
              />
              <circle fill="currentColor" cx="92.37" cy="14.45" r="0.14" />
              <circle fill="currentColor" cx="101.3" cy="10.74" r="0.18" />
              <circle fill="currentColor" cx="110.44" cy="7.58" r="0.22" />
              <path
                fill="currentColor"
                d="M119.76,5.26a.27.27,0,0,1,0-.53.27.27,0,0,1,0,.53Z"
              />
              <path
                fill="currentColor"
                d="M129.21,3.29a.31.31,0,1,1,.3-.31A.3.3,0,0,1,129.21,3.29Z"
              />
              <path
                fill="currentColor"
                d="M138.77,1.9a.35.35,0,0,1,0-.69.35.35,0,1,1,0,.69Z"
              />
              <circle fill="currentColor" cx="148.4" cy="0.71" r="0.39" />
              <path
                fill="currentColor"
                d="M158.07.93a.47.47,0,0,1,0-.93.47.47,0,0,1,.47.46A.47.47,0,0,1,158.07.93Z"
              />
              <circle fill="currentColor" cx="167.74" cy="0.81" r="0.53" />
              <path
                fill="currentColor"
                d="M177.36,2.35a.61.61,0,1,1,.6-.61A.6.6,0,0,1,177.36,2.35Z"
              />
              <circle fill="currentColor" cx="186.91" cy="3.27" r="0.67" />
              <circle fill="currentColor" cx="196.34" cy="5.39" r="0.74" />
              <path
                fill="currentColor"
                d="M205.63,8.9a.82.82,0,0,1-.81-.82.81.81,0,0,1,.81-.81.82.82,0,0,1,.82.81A.82.82,0,0,1,205.63,8.9Z"
              />
              <path
                fill="currentColor"
                d="M214.73,12.23a.88.88,0,1,1,.88-.88A.87.87,0,0,1,214.73,12.23Z"
              />
              <path
                fill="currentColor"
                d="M223.61,16.13a1,1,0,1,1,.95-1A1,1,0,0,1,223.61,16.13Z"
              />
              <circle fill="currentColor" cx="232.23" cy="19.54" r="1.02" />
              <path
                fill="currentColor"
                d="M240.58,25.52a1.09,1.09,0,1,1,1.09-1.09A1.09,1.09,0,0,1,240.58,25.52Z"
              />
              <circle fill="currentColor" cx="248.6" cy="29.82" r="1.16" />
              <circle fill="currentColor" cx="256.29" cy="35.69" r="1.23" />
              <circle fill="currentColor" cx="263.6" cy="42.02" r="1.3" />
              <circle fill="currentColor" cx="270.52" cy="48.78" r="1.37" />
              <path
                fill="currentColor"
                d="M277,57.4A1.44,1.44,0,1,1,278.44,56,1.43,1.43,0,0,1,277,57.4Z"
              />
              <circle fill="currentColor" cx="283.03" cy="63.52" r="1.51" />
              <path
                fill="currentColor"
                d="M288.57,73a1.59,1.59,0,1,1,1.59-1.58A1.58,1.58,0,0,1,288.57,73Z"
              />
              <path
                fill="currentColor"
                d="M293.62,81.35a1.66,1.66,0,1,1,1.65-1.65A1.65,1.65,0,0,1,293.62,81.35Z"
              />
              <path
                fill="currentColor"
                d="M298.14,90a1.73,1.73,0,1,1,1.72-1.73A1.73,1.73,0,0,1,298.14,90Z"
              />
              <path
                fill="currentColor"
                d="M302.12,98.85a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,302.12,98.85Z"
              />
              <path
                fill="currentColor"
                d="M305.56,108a1.87,1.87,0,1,1,1.86-1.87A1.87,1.87,0,0,1,305.56,108Z"
              />
              <circle fill="currentColor" cx="308.43" cy="115.32" r="1.93" />
              <circle fill="currentColor" cx="310.73" cy="124.71" r="2" />
              <path
                fill="currentColor"
                d="M312.45,136.28a2.06,2.06,0,1,1,2.06-2.06A2.06,2.06,0,0,1,312.45,136.28Z"
              />
              <circle fill="currentColor" cx="313.59" cy="143.83" r="2.1" />
              <circle fill="currentColor" cx="314.13" cy="153.48" r="2.14" />
              <circle fill="currentColor" cx="314.09" cy="163.15" r="2.18" />
              <circle
                fill="currentColor"
                cx="313.45"
                cy="172.8"
                r="2.22"
                transform="translate(33.85 396.26) rotate(-67.5)"
              />
              <circle
                fill="currentColor"
                cx="312.21"
                cy="182.39"
                r="2.27"
                transform="translate(74.07 454.48) rotate(-79.1)"
              />
              <circle fill="currentColor" cx="310.39" cy="191.89" r="2.31" />
              <circle fill="currentColor" cx="307.99" cy="201.25" r="2.32" />
              <circle
                fill="currentColor"
                cx="305.01"
                cy="210.45"
                r="2.33"
                transform="matrix(0.29, -0.96, 0.96, 0.29, 15.2, 441.38)"
              />
              <circle
                fill="currentColor"
                cx="301.46"
                cy="219.44"
                r="2.35"
                transform="translate(-16.64 413.98) rotate(-67.5)"
              />
              <circle
                fill="currentColor"
                cx="297.36"
                cy="228.2"
                r="2.36"
                transform="translate(-53.09 361.18) rotate(-58.28)"
              />
              <circle fill="currentColor" cx="292.73" cy="236.69" r="2.38" />
              <circle
                fill="currentColor"
                cx="287.59"
                cy="244.87"
                r="2.39"
                transform="translate(-7.25 480.96) rotate(-79.1)"
              />
              <circle
                fill="currentColor"
                cx="281.95"
                cy="252.73"
                r="2.41"
                transform="translate(-59.44 416.51) rotate(-67.5)"
              />
              <circle fill="currentColor" cx="275.85" cy="260.24" r="2.4" />
              <circle
                fill="currentColor"
                cx="269.3"
                cy="267.36"
                r="2.38"
                transform="translate(-52.78 468.03) rotate(-76.72)"
              />
              <circle
                fill="currentColor"
                cx="262.33"
                cy="274.06"
                r="2.37"
                transform="translate(-64.67 466.41) rotate(-76.72)"
              />
              <circle fill="currentColor" cx="254.96" cy="280.32" r="2.35" />
              <circle fill="currentColor" cx="247.21" cy="286.11" r="2.34" />
              <circle
                fill="currentColor"
                cx="239.12"
                cy="291.41"
                r="2.32"
                transform="translate(-99.43 457.18) rotate(-76.72)"
              />
              <circle fill="currentColor" cx="230.72" cy="296.19" r="2.31" />
              <circle fill="currentColor" cx="222.04" cy="300.45" r="2.28" />
              <circle
                fill="currentColor"
                cx="213.11"
                cy="304.16"
                r="2.24"
                transform="translate(-152.66 239.78) rotate(-45)"
              />
              <path
                fill="currentColor"
                d="M204,309.51a2.2,2.2,0,1,1,2.19-2.2A2.21,2.21,0,0,1,204,309.51Z"
              />
              <path
                fill="currentColor"
                d="M194.65,312.05a2.15,2.15,0,1,1,2.15-2.15A2.15,2.15,0,0,1,194.65,312.05Z"
              />
              <circle fill="currentColor" cx="185.2" cy="311.91" r="2.11" />
              <path
                fill="currentColor"
                d="M175.64,315.41a2.07,2.07,0,1,1,2.07-2.07A2.07,2.07,0,0,1,175.64,315.41Z"
              />
              <path
                fill="currentColor"
                d="M166,316.2a2,2,0,1,1,2-2A2,2,0,0,1,166,316.2Z"
              />
              <path
                fill="currentColor"
                d="M156.34,316.38a1.95,1.95,0,1,1,1.95-1.94A1.94,1.94,0,0,1,156.34,316.38Z"
              />
              <circle fill="currentColor" cx="146.68" cy="314.09" r="1.88" />
              <circle fill="currentColor" cx="137.05" cy="313.16" r="1.81" />
              <path
                fill="currentColor"
                d="M127.5,313.37a1.74,1.74,0,1,1,1.74-1.74A1.74,1.74,0,0,1,127.5,313.37Z"
              />
              <path
                fill="currentColor"
                d="M118.07,311.18a1.67,1.67,0,1,1,1.66-1.66A1.66,1.66,0,0,1,118.07,311.18Z"
              />
              <circle fill="currentColor" cx="108.78" cy="306.82" r="1.6" />
              <circle fill="currentColor" cx="99.68" cy="303.56" r="1.53" />
              <circle fill="currentColor" cx="90.8" cy="299.73" r="1.46" />
              <circle fill="currentColor" cx="82.18" cy="295.37" r="1.39" />
              <path
                fill="currentColor"
                d="M73.83,291.79a1.32,1.32,0,1,1,1.32-1.31A1.31,1.31,0,0,1,73.83,291.79Z"
              />
              <path
                fill="currentColor"
                d="M65.81,286.33a1.25,1.25,0,1,1,1.24-1.25A1.25,1.25,0,0,1,65.81,286.33Z"
              />
              <circle
                fill="currentColor"
                cx="58.12"
                cy="279.21"
                r="1.17"
                transform="translate(-226.8 291.86) rotate(-80.78)"
              />
              <circle fill="currentColor" cx="50.8" cy="272.89" r="1.1" />
              <path
                fill="currentColor"
                d="M43.89,267.16a1,1,0,1,1,1-1A1,1,0,0,1,43.89,267.16Z"
              />
              <circle fill="currentColor" cx="37.41" cy="258.95" r="0.96" />
              <path
                fill="currentColor"
                d="M31.38,252.28a.9.9,0,1,1,.89-.89A.89.89,0,0,1,31.38,252.28Z"
              />
              <path
                fill="currentColor"
                d="M25.83,244.29a.83.83,0,1,1,.82-.82A.82.82,0,0,1,25.83,244.29Z"
              />
              <circle fill="currentColor" cx="20.79" cy="235.21" r="0.75" />
              <circle fill="currentColor" cx="16.27" cy="226.67" r="0.68" />
              <circle fill="currentColor" cx="12.28" cy="217.86" r="0.61" />
              <path
                fill="currentColor"
                d="M8.84,209.36a.54.54,0,1,1,.55-.54A.54.54,0,0,1,8.84,209.36Z"
              />
              <circle fill="currentColor" cx="5.97" cy="199.59" r="0.47" />
              <path
                fill="currentColor"
                d="M3.67,190.6a.4.4,0,1,1,.4-.4A.4.4,0,0,1,3.67,190.6Z"
              />
              <path
                fill="currentColor"
                d="M2,181a.35.35,0,1,1,.35-.35A.35.35,0,0,1,2,181Z"
              />
              <path
                fill="currentColor"
                d="M.81,171.39a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.31.31.31,0,0,1,.31.31A.3.3,0,0,1,.81,171.39Z"
              />
              <path
                fill="currentColor"
                d="M.26,161.7a.27.27,0,0,1,0-.53.27.27,0,1,1,0,.53Z"
              />
              <circle fill="currentColor" cx="0.31" cy="151.76" r="0.22" />
              <circle fill="currentColor" cx="0.95" cy="142.11" r="0.18" />
              <circle fill="currentColor" cx="2.18" cy="132.52" r="0.14" />
              <path
                fill="currentColor"
                d="M4,123.12a.1.1,0,0,1-.1-.1.11.11,0,0,1,.1-.1.1.1,0,0,1,.1.1A.09.09,0,0,1,4,123.12Z"
              />
              <path
                fill="currentColor"
                d="M6.41,113.74a.08.08,0,0,1-.09-.08.09.09,0,0,1,.09-.09.09.09,0,0,1,.08.09A.08.08,0,0,1,6.41,113.74Z"
              />
              <path
                fill="currentColor"
                d="M9.39,104.54a.08.08,0,0,1-.07-.08.07.07,0,0,1,.14,0A.08.08,0,0,1,9.39,104.54Z"
              />
              <circle fill="currentColor" cx="12.93" cy="95.47" r="0.06" />
              <path
                fill="currentColor"
                d="M17,86.76s0,0,0-.05a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,17,86.76Z"
              />
              <path
                fill="currentColor"
                d="M21.66,78.25s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0Z"
              />
              <path
                fill="currentColor"
                d="M26.8,70.05h0s0,0,0,0a0,0,0,0,1,0,0Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
