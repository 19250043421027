import clsx from 'clsx';
import {graphql, PageProps} from 'gatsby';
import React from 'react';
import LuArticleHero from '../components/lu-article-hero';
import DefaultLayout from '../layouts/default';
import heroImage from '../images/about-hero.jpg';
import LuArticleToc from '../components/lu-article-toc';
import LuArticle, {ViewModel as ArticleViewModel} from '../components/article';
import SectionStatistics, {
  ViewModel as StatisticsViewModel,
} from '../components/section-statistics';
import LuTimeline, {
  ViewModel as TimelineViewModel,
} from '../components/lu-timeline';
import * as styles from './about.module.scss';
import {Col, Row} from 'antd';
import {GAP_SIZE_2, GAP_SIZE_3, GAP_SIZE_BASE} from '../constants';
import LuPortrait from '../components/lu-portrait';
import LuCarouselThumb, {
  ViewModel as CarouselViewModel,
} from '../components/lu-carousel-thumb';
import SectionCertificationAndAudit, {
  ViewModel as CertificationViewModel,
} from '../components/section-certification-and-audit';
import {useTranslation} from 'react-i18next';
import portrait1Image from '../images/谢碧珍.jpg';
import portrait2Image from '../images/陈晓源.jpg';
import portrait3Image from '../images/谢碧玉.jpg';
import portrait4Image from '../images/谢碧峰.jpg';
import portrait5Image from '../images/王宜林.jpg';
import portrait6Image from '../images/芦晓青.jpg';
import portrait7Image from '../images/孙秀娟.jpg';
import portrait8Image from '../images/苏发明.jpg';
import portrait9Image from '../images/王贤丰.jpg';
import portrait10Image from '../images/邹元生.jpg';
import factory1Image from '../images/about-factory-1.jpg';
import factory2Image from '../images/about-factory-2.jpg';
import factory3Image from '../images/about-factory-3.jpg';
import factory4Image from '../images/about-factory-4.jpg';
import factory5Image from '../images/about-factory-5.jpg';
import certBvImage from '../images/certification-bv.jpg';
import certCleanDesignImage from '../images/certification-clean-design.jpg';
import certGotsImage from '../images/certification-gots.jpg';
import certGrsImage from '../images/certification-grs.jpg';
import certHiImage from '../images/certification-hi.jpg';
import certIsoImage from '../images/certification-iso.jpg';
import certOekoImage from '../images/certification-oeko.jpg';
import certVfImage from '../images/certification-vf.jpg';

const AboutPage = ({data, location}: PageProps) => {
  const {t} = useTranslation('about');
  const PAGE_DATA = {
    title: 'About Us',
    heroImage: heroImage,
    subTitle: t('走进新联和'),
    tabs: [
      {
        title: t('集团简介'),
        anchor: 'about-1',
      },
      {
        title: t('发展历程'),
        anchor: 'about-2',
      },
      {
        title: t('管理团队'),
        anchor: 'about-3',
      },
      {
        title: t('智慧工厂'),
        anchor: 'about-4',
      },
      {
        title: t('基地介绍'),
        anchor: 'about-5',
      },
      {
        title: t('品牌认证'),
        anchor: 'about-6',
      },
    ],
  };
  const STATISTICS_DATA: StatisticsViewModel = [
    {
      title: '2000',
      description: t('成立于2000年'),
    },
    {
      title: '1000',
      description: t('员工1000多名'),
    },
    {
      title: t('20亿'),
      description: t('年贸易总额20多亿'),
    },
  ];
  const TIMELINE_DATA: TimelineViewModel = {
    items: [
      {
        title: t('2006年9月'),
        content: t('染整厂二期竣工投产'),
      },
      {
        title: t('2007年10月'),
        content: t('织造厂二期竣工投产、第100台针织机开始运行'),
      },
      {
        title: t('2010年'),
        content: t('染色机达150台，热定型机达14台'),
      },
      {
        title: t('2012年5月'),
        content: t('织造厂三期竣工投产'),
      },
      {
        title: t('2014年6月'),
        content: t('染整厂三期动工建设'),
      },
      {
        title: t('2015年'),
        content: t('染整厂通过M&S验厂'),
      },
      {
        title: t('2016年12月'),
        content: t('检测中心建成使用'),
      },
      {
        title: t('2017年6月'),
        content: t('生产基地通过INDITEX RTM验厂'),
      },
      {
        title: t('2017年10月'),
        content: t('检测中心通过法国BV认证'),
      },
      {
        title: t('2018年4月'),
        content: t('生产基地通过VF集团验厂'),
      },
      {
        title: t('现今'),
        content: t(
          '生产基地拥有70台花边机，116台纬编机，172台染色机，16台热定型机。'
        ),
      },
    ],
  };
  const MANAGMENT_TEAM_DATA = [
    {
      name: t('谢碧珍'),
      text: t('新联和集团总裁'),
      imageUrl: portrait1Image,
      important: true,
    },
    {
      name: t('陈晓源'),
      text: t('新联和集团总经理'),
      imageUrl: portrait2Image,
      important: false,
    },
    {
      name: t('谢碧玉'),
      text: t('新联和集团营销中心副总'),
      imageUrl: portrait3Image,
      important: false,
    },
    {
      name: t('谢碧峰'),
      text: t('新联和集团企划中心副总'),
      imageUrl: portrait4Image,
      important: false,
    },
    {
      name: t('王宜林'),
      text: [t('新联和集团供应链中心副总'), t('新联和集团销售项目管理部总监')],
      imageUrl: portrait5Image,
      important: false,
    },
    {
      name: t('芦晓青'),
      text: t('新联和集团市场营销部总监'),
      imageUrl: portrait6Image,
      important: false,
    },
    {
      name: t('孙秀娟'),
      text: t('新联和集团人力行政中心总监'),
      imageUrl: portrait7Image,
      important: false,
    },
    {
      name: t('苏发明'),
      text: t('新联和集团织造生产技术总监'),
      imageUrl: portrait8Image,
      important: false,
    },
    {
      name: t('王贤丰'),
      text: t('新联和集团染整生产技术总监'),
      imageUrl: portrait9Image,
      important: false,
    },
    {
      name: t('邹元生'),
      text: t('新联和集团品牌部总监'),
      imageUrl: portrait10Image,
      important: false,
    },
  ];
  const CAROUSEL_DATA: CarouselViewModel = {
    images: [
      {
        url: factory1Image,
      },
      {
        url: factory2Image,
      },
      {
        url: factory3Image,
      },
      {
        url: factory4Image,
      },
      {
        url: factory5Image,
      },
    ],
  };
  const CERTIFICATION_DATA: CertificationViewModel = [
    {
      imageUrl: certIsoImage,
      name: t('ISO证书'),
      detail: (
        <>
          <p>
            {t(
              '联丰染整、恒益纺织积极参与体系标准建设，2006年起通过ISO9001体系认证，2014年起持续通过ISO9001、ISO14001、OHSAS18001三体系认证。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certHiImage,
      name: 'Higg Index',
      detail: (
        <>
          <p>
            {t(
              '联丰染整、恒益纺织自2018年开始参与HiggFEM审计专案，均符合Level1要求，部分符合达到Level2-3要求；9月份对联丰染整HiggFEM进行协力厂商审计，恒益纺织因为是纺织厂未有严重污染因素则不需协力厂商审计，各大品牌均要求染厂需进行协力厂商审计。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certVfImage,
      name: 'VF',
      detail: (
        <>
          <p>
            {t(
              '联丰染整2017年起积极参与VF合规验厂，并于2018年获得VF合规验厂认可，成为VF集团核心合作伙伴。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certBvImage,
      name: 'BV',
      detail: (
        <>
          <p>
            {t(
              '2017年10月6日，我们的技术中心正式通过法国BV认证，具有23个纺织品物理和化学性能日常测试的能力。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certGrsImage,
      name: 'GRS',
      detail: (
        <>
          <p>
            {t(
              '2019年7月24日恒益纺织、联丰染整正式通过CU现场GRS审核，预计8月15日左右获得正式证书，贸易、织造、染整均可开具有效交易证书。同样的GRS也有对应的简单的版本RCS，RCS认证只认证再生成分，产品再生只需成分大于5%，而我司的再生产品认证是GRS。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certOekoImage,
      name: 'Oeko',
      detail: (
        <>
          <p>
            {t(
              '联丰染整、恒益纺织积极参与纺织业可持续发展，棉针织、蕾丝产品均符合Oeko-TexSTANDARD100标准。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certGotsImage,
      name: 'GOTS',
      detail: (
        <>
          <p>
            {t(
              '2019年7月24日恒益纺织、联丰染整率先通过CU现场GOTS审核，预计8月15日左右获得正式证书，贸易、织造、染整均可开具有效交易证书。'
            )}
          </p>
        </>
      ),
    },
    {
      imageUrl: certCleanDesignImage,
      name: 'CLEAN-DESIGN',
      detail: (
        <>
          <p>
            {t(
              '联丰染整2019年7月率先参与AII发起的CbD节能减排项目，进一步提升节能减排成效，为同行们提供先行经验。CbD是辅助Higg优化提升工厂环境能源绩效的执行工具和现实行动。'
            )}
          </p>
        </>
      ),
    },
  ];
  const ARTICLE_BODY_DATA: ArticleViewModel = {
    body: [
      {
        type: 'rich-text',
        html: (
          <div id="about-1">
            <h3>{t('新联和集团介绍')}</h3>
            <p>
              {t(
                '新联和集团是港资独资企业，拥有完整的研发，检测，贸易体系。旗下拥有联丰染整，恒益蕾丝，联丰针织，联丰检测四大品牌。'
              )}
            </p>
            <p>
              {t(
                '从2000年开始以香港为核心贸易基地，陆续在中国华南地区开设分支机构，在世界主要城市设立联络点。并在福州滨海工业区建成研发与纺织基地（福建恒益纺织有限公司），检测与染整基地（长乐联丰染整有限公司）。充分发挥集团优势，全面支持旗下四大品牌的成长与发展。'
              )}
            </p>
            <p>
              {t(
                '集团总占地面积300余亩，员工1000多人，年产量达45000多吨。主要与国内各高端市场品牌，欧美以及东南亚等国家和地区的国际知名品牌长期合作。集团技术中心已拥有独立检测和认证产品的资质，年贸易总额逾二十多亿元。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: 'custom',
        component: (
          <div className={styles.statistics}>
            <SectionStatistics vm={STATISTICS_DATA} />
          </div>
        ),
      },
      {
        type: 'rich-text',
        html: (
          <div id="about-2">
            <h3>{t('发展历程')}</h3>
            <p>
              {t(
                '上世纪80年代，第一家纺织贸易公司在香港成立。20世纪90年代初，经过十年的发展，第一个工厂于广东省博罗市，建成投产。从2000年开始，以香港为核心，我们陆续在中国华南地区开设分支机构，在世界主要城市设立联络点。并在福州滨海工业区建成研发与纺织基地（福建恒益纺织有限公司），检测与染整基地（长乐联丰染整有限公司）。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: 'custom',
        component: (
          <div className={styles.timeline}>
            <LuTimeline vm={TIMELINE_DATA} />
          </div>
        ),
      },
      {
        type: 'custom',
        component: (
          <div className={styles.managmentTeam} id="about-3">
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.decoration}>MANAGMENT TEAM</div>
                <div className={styles.title}>{t('管理团队')}</div>
              </div>
              <Row
                gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}
                justify="space-between"
              >
                {MANAGMENT_TEAM_DATA.map(member => (
                  <React.Fragment key={member.name}>
                    {member.important && <Col span={6} md={8} />}
                    <Col span={8}>
                      <LuPortrait
                        name={member.name}
                        text={member.text}
                        imageUrl={member.imageUrl}
                      />
                    </Col>
                    {member.important && <Col span={6} md={8} />}
                  </React.Fragment>
                ))}
              </Row>
            </div>
          </div>
        ),
      },
      {
        type: 'rich-text',
        html: (
          <div id="about-4">
            <h3>{t('ERP智慧工厂')}</h3>
            <p>
              {t(
                '自2004年起，公司全面推行ERP管理系统，实现生产数据实时反馈。发展至今，ERP系统已经深入公司日常生产运作，也实现设备多终端运行。通过2019年公司物联网升级建设的完成，将实现全方位覆盖的公司生产设备生产数据实时反馈，多年积累也实现了公司生产经营大数据分析所需，为公司经营方向提供真实有力的大数据。'
              )}
            </p>
            <p>
              {t(
                'DTC电脑对色、自动配方系统，公司投资引进国际先进分光对色仪，并配套引进自动化配方滴液系统，实现颜色配方自动化。'
              )}
            </p>
            <p>
              {t(
                'SG染化料自动称料系统，公司投资引进先进自动称料系统，实现染料称重自动控制系统。'
              )}
            </p>
            <p>
              {t(
                'KarlMayerCOS系统，公司投资引进国际先进经编机，配套引进KMCOS实时在线系统，实现在线查看生产状态，实时反馈生产数据。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: 'custom',
        component: (
          <section id="about-7" className={styles.carousel}>
            <LuCarouselThumb vm={CAROUSEL_DATA} />
          </section>
        ),
      },
      {
        type: 'rich-text',
        html: (
          <div id="about-5">
            <h3>{t('基地介绍')}</h3>
            <p>{t('全功能生产基地，采纳国际化标准')}</p>
            <p>
              {t(
                '纺织生产基地坐落于中国纺织名镇——福建省福州滨海工业区(长乐两港工业区)，总占地面积330亩(22万平方米)。其主要生产功能以针织织造，染整加工为主，并集工贸、科研、生产、销售为一体，打造可实现全方位功能国际化标准现代纺织生产基地。'
              )}
            </p>
          </div>
        ),
      },
      {
        type: 'custom',
        component: (
          <div className={styles.certification} id="about-6">
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.decoration}>CERTIFICATION AND AUDIT</div>
                <div className={styles.title}>{t('品牌认证')}</div>
              </div>
              <SectionCertificationAndAudit vm={CERTIFICATION_DATA} />
            </div>
          </div>
        ),
      },
    ],
  };
  return (
    <DefaultLayout location={location}>
      <LuArticleHero
        bgImageUrl={PAGE_DATA.heroImage}
        title={PAGE_DATA.title}
        subTitle={PAGE_DATA.subTitle}
        showMask
      />
      <LuArticleToc tabs={PAGE_DATA.tabs} />
      <LuArticle vm={ARTICLE_BODY_DATA} />
    </DefaultLayout>
  );
};

export default AboutPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
